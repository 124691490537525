import { PageEvents } from "~/enums/tracking/tracking-events.enums";

enum CourseLandingPageSourceValues {
  DiscoveryPage = 'discovery_page',
  CourseLibrary = 'course_library',
  CourseCompletion = 'course_completion',
  Direct = 'direct',
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { user } = useUserStore();
  const { dispatchTrackingEvent } = useTracking();

  const pathToTrackingValueMap: Record<string, CourseLandingPageSourceValues | null> = {
    '/dashboard': CourseLandingPageSourceValues.DiscoveryPage,
    '/courses': CourseLandingPageSourceValues.CourseLibrary,
    [to.path]: CourseLandingPageSourceValues.Direct,
  };

  let trackingValue = pathToTrackingValueMap[from.path] || null;
  if (!trackingValue) {
    if (from.path.includes('/courses/') && from.path.includes('/recommendations')) {
      trackingValue = CourseLandingPageSourceValues.CourseCompletion;
    } else {
      return;
    }
  }

  dispatchTrackingEvent(PageEvents.CourseLandingPageViewed, {
    user: {
      plan_type: user.value?.subscription?.type,
    },
    user_specialty: user.value?.specialty,
    course_id: to.params.courseId,
    landing_page_source: trackingValue,
  });
});